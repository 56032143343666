import clsx from 'clsx';
import * as React from 'react';
import { FC } from 'react';

import { Category } from '@redsleeve/oilynx-domain';

import CategoryDotsMenu from '@components/CategoryDotsMenu';

type CategoryRowProps = {
  category: Category;
  deleteCategory: (category: Category) => unknown;
  updateCategory: (category: Category) => unknown;
  switchArrow: () => unknown;
  arrow: { [key: string]: boolean };
};

const CategoryRow: FC<CategoryRowProps> = ({
  category,
  deleteCategory,
  updateCategory,
  switchArrow,
  arrow,
}) => {
  return (
    <div
      className={clsx(
        'flex border-b items-center border-gray-700 CategoryRow-wrapper p-2 hover:bg-background-avg hover:rounded-xl '
      )}
    >
      <div
        className={clsx(
          ' w-1/4 text-sm font-body text-white opacity-60',
          category.parentId && 'text-white opacity-100 pl-10'
        )}
      >
        {!category.parentId ? (
          <>
            <span
              className={clsx(
                'cursor-pointer	inline-block transform ',
                arrow[category.id] ? 'rotate-90' : 'rotate-270'
              )}
              onClick={switchArrow}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-3 w-3"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </span>
            <span className={'pl-2'}>{category.name}</span>
          </>
        ) : (
          category.name
        )}
      </div>
      {category.density && (
        <div className="text-right text-gray-400">
          <span className="text-white ">{category.density}</span>
        </div>
      )}
      <div className="flex-1 flex flex-row-reverse">
        <CategoryDotsMenu
          deleteButton={'Delete'}
          showOwnerEdit={false}
          editButton={'Edit'}
          deleteHandler={() => deleteCategory(category)}
          editHandler={() => updateCategory(category)}
        />
      </div>
    </div>
  );
};

export default CategoryRow;
