import clsx from 'clsx';
import { useFormik } from 'formik';
import * as React from 'react';
import { FC } from 'react';

import Button from '@components/Button';

import './ButtonSwitchInput.css';

type ButtonSwitchInputProps = {
  className?: string;

  formik: ReturnType<typeof useFormik>;
  name: string;
  values: {
    label: string;
    value: string;
  }[];
  showError?: boolean;
};

const ButtonSwitchInput: FC<ButtonSwitchInputProps> = ({
  className,

  formik,
  name,
  values,
  showError = true,
}) => {
  return (
    <>
      <div className={clsx(className, 'ButtonSwitchInput-wrapper flex')}>
        {values.map((val) => (
          <Button
            key={val.value}
            variant="outlined"
            className={clsx(
              'flex-1',
              formik.values[name] === val.value && 'ButtonSwitchInput-active'
            )}
            onClick={(event) => {
              event.preventDefault();
              formik.setFieldValue(name, val.value);
            }}
          >
            {val.label}
          </Button>
        ))}
      </div>
      {showError && formik.touched[name] && formik.errors[name] ? (
        <div className="text-error">{formik.errors[name]}</div>
      ) : null}
    </>
  );
};

export default ButtonSwitchInput;
