import { useMutation, useQuery } from '@apollo/client';
import Layout from '@layouts';
import {
  getErrorMessages,
  getInvalidFields,
  getQueryName,
  useModal,
} from '@lib';
import clsx from 'clsx';
import { useFormik } from 'formik';
import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import * as Yup from 'yup';

import {
  Category,
  CreateCategoryDocument,
  DeleteCategoryDocument,
  GetCategoriesDocument,
  UpdateCategoryDocument,
} from '@redsleeve/oilynx-domain';

import Button from '@components/Button';
import ButtonSwitchInput from '@components/form/ButtonSwitchInput';
import SelectInput from '@components/form/SelectInput';
import TextInput from '@components/form/TextInput';
import Modal from '@components/modal/Modal';

import { useOrderedCategories } from '@lib/hooks/useOrderedCategories';

import CategoryRow from './CategoryRow';
import './category-management.css';

const coreCategoryValidationSchema = Yup.object({
  name: Yup.string().required('Categories should have a name'),
  density: Yup.mixed().when('switchCategory', (switchCategory: string) =>
    switchCategory === 'category'
      ? undefined
      : Yup.number().nullable().min(0, 'Density must be a positive number')
  ),
});

const parentCategoryValidationSchema = Yup.object({
  selectParent: Yup.mixed().when('switchCategory', (switchCategory: string) =>
    switchCategory === 'category'
      ? undefined
      : Yup.object().test(
          'selectParent',
          'Please select a parent category',
          (val) => !!val?.label
        )
  ),
});

const CategoryManagementPage: FC = () => {
  const categoriesQuery = useQuery(GetCategoriesDocument);
  const orderedCategories = useOrderedCategories(categoriesQuery.data);
  const [createCategory] = useMutation(CreateCategoryDocument, {
    refetchQueries: [getQueryName(GetCategoriesDocument)],
  });
  const [deleteCategory] = useMutation(DeleteCategoryDocument, {
    refetchQueries: [getQueryName(GetCategoriesDocument)],
  });
  const [updateCategory] = useMutation(UpdateCategoryDocument);
  const createModal = useModal<Category['parentId']>();
  const deleteModal = useModal<Category>();
  const updateModal = useModal<Category>();

  const [categoryState, setCategoryState] = useState<{
    [key: string]: boolean;
  }>({});

  useEffect(() => {
    setCategoryState(
      orderedCategories
        .filter((it) => !it.parentId)
        .map<[string, boolean]>((it) => [it.id, true])
        .reduce((acc, it) => ({ ...acc, [it[0]]: it[1] }), {})
    );
  }, [orderedCategories, setCategoryState]);

  const categoryType = [
    { value: 'category', label: 'Category' },
    { value: 'subcategory', label: 'Subcategory' },
  ];
  const category = orderedCategories
    .filter((cat) => !cat.parentId)
    .map((item) => {
      return { value: item.id, label: item.name };
    });
  const createFormik = useFormik({
    initialValues: {
      switchCategory: 'category',
      name: '',
      density: 1000,
      selectParent: {
        value: '',
        label: '',
      },
    },
    validationSchema: coreCategoryValidationSchema.concat(
      parentCategoryValidationSchema
    ),
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      setSubmitting(true);
      try {
        await createCategory({
          variables: {
            category: {
              name: values.name,
              parentId:
                values.switchCategory === 'subcategory'
                  ? values.selectParent.value
                  : null,
              density:
                values.switchCategory === 'subcategory'
                  ? values.density || null
                  : null,
            },
          },
        });

        // success
        createModal.close();
        createFormik.resetForm();

        // TODO success modal or smth
      } catch (ex) {
        const formError = getInvalidFields(ex);
        if (formError) setErrors(formError);
        const errorMessages = getErrorMessages(ex);
        if (errorMessages) alert(errorMessages.join('\n'));
        else
          console.error(
            '[CategoryManagementPage] createCategoryForm onSubmit',
            ex
          );
      } finally {
        setSubmitting(false);
      }
    },
  });
  const updateFormik = useFormik({
    initialValues: {
      switchCategory: 'subcategory',
      name: '',
      density: 1000,
    } as {
      name: string;
      density?: number;
      switchCategory: string;
    },
    validationSchema: coreCategoryValidationSchema,
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      setSubmitting(true);
      try {
        await updateCategory({
          variables: {
            categoryId: updateModal.data.id,
            category: {
              name: values.name,
              density:
                values.switchCategory === 'subcategory'
                  ? values.density || null
                  : null,
              parentId: updateModal.data.parentId,
            },
          },
        });
        // success
        updateModal.close();
        updateFormik.resetForm();

        // TODO success modal or smth
      } catch (ex) {
        const formError = getInvalidFields(ex);
        if (formError) setErrors(formError);
        const errorMessages = getErrorMessages(ex);
        if (errorMessages) alert(errorMessages.join('\n'));
        else
          console.error(
            '[CategoryManagementPage] updateCategoryForm onSubmit',
            ex
          );
      } finally {
        setSubmitting(false);
      }
    },
  });

  async function handleCategoryDelete() {
    try {
      await deleteCategory({
        variables: {
          categoryId: deleteModal.data.id,
        },
      });
      // success
      deleteModal.close();

      // TODO success modal or smth
    } catch (ex) {
      const errorMessages = getErrorMessages(ex);
      if (errorMessages) alert(errorMessages.join('\n'));
      else console.error('[CategoryManagementPage] handleCategoryDelete', ex);
    }
  }

  function renderModals() {
    return (
      <div className={'CreateCategory-wrapper'}>
        <Modal
          title="Add category"
          contentClassName="MutationCategory-wrapper h-screen lg:h-auto"
          size="w-screen h-screen lg:h-auto lg:max-w-md lg:w-full"
          control={createModal}
          formProps={{
            onSubmit: createFormik.handleSubmit,
          }}
          actions={
            <>
              <Button
                className="ActionButton"
                type="reset"
                onClick={() => {
                  createModal.close();
                  createFormik.resetForm();
                }}
              >
                Cancel
              </Button>
              <>
                <Button
                  className="ActionButton "
                  type="submit"
                  name="submit"
                  variant="primary"
                >
                  Add category
                </Button>
              </>
            </>
          }
        >
          <ButtonSwitchInput
            formik={createFormik}
            name={'switchCategory'}
            values={categoryType}
          />
          {createFormik.values.switchCategory === 'subcategory' ? (
            <div className="mt-5">
              <SelectInput
                formik={createFormik}
                name={'selectParent'}
                label={'Parent'}
                values={category}
              />
            </div>
          ) : null}
          <div className={clsx('flex flex-col mt-5')}>
            <div className={clsx('w-full mb-5')}>
              <TextInput
                formik={createFormik}
                type="text"
                name="name"
                label="Category Name"
              />
            </div>
            {createFormik.values.switchCategory === 'subcategory' ? (
              <div className={'w-full'}>
                <TextInput
                  formik={createFormik}
                  type="number"
                  name="density"
                  label="Density"
                  endPiece={
                    <span>
                      kg/m<sup>3</sup>
                    </span>
                  }
                />
              </div>
            ) : null}
          </div>
        </Modal>
        <Modal
          title="Permanently delete category?"
          contentClassName="MutationCategory-wrapper h-screen lg:h-auto"
          size="w-screen h-screen lg:h-auto lg:max-w-md lg:w-full"
          control={deleteModal}
          actions={
            <>
              <Button
                className="ActionButton"
                type="reset"
                onClick={() => {
                  deleteModal.close();
                }}
              >
                Cancel
              </Button>
              <>
                <Button
                  className="ActionButton"
                  type="submit"
                  name="submit"
                  variant="primary"
                  onClick={handleCategoryDelete}
                >
                  Delete
                </Button>
              </>
            </>
          }
        >
          <p className={'text-sm opacity-75 fontFamily-emp '}>
            Deleting {deleteModal.data?.name} will permanently remove it and ALL
            TRADES in this category!
          </p>
        </Modal>
        <Modal
          title="Edit category"
          control={updateModal}
          contentClassName="MutationCategory-wrapper h-screen lg:h-auto"
          size="w-screen h-screen lg:h-auto lg:max-w-md lg:w-full"
          formProps={{
            onSubmit: updateFormik.handleSubmit,
          }}
          actions={
            <>
              <Button
                className="ActionButton "
                type="reset"
                onClick={() => {
                  updateModal.close();
                  updateFormik.resetForm();
                }}
              >
                Cancel
              </Button>
              <Button
                className="ActionButton"
                type="submit"
                name="submit"
                variant="primary"
              >
                Save
              </Button>
            </>
          }
        >
          <div className={clsx('flex flex-col mt-5')}>
            <div className={clsx('w-full mb-5')}>
              <TextInput
                formik={updateFormik}
                type="text"
                name="name"
                label="Category Name"
              />
            </div>

            {updateFormik.values.switchCategory === 'subcategory' ? (
              <div className={'w-full'}>
                <TextInput
                  formik={updateFormik}
                  type="number"
                  name="density"
                  label="Density"
                  endPiece={
                    <span>
                      kg/m<sup>3</sup>
                    </span>
                  }
                />
              </div>
            ) : null}
          </div>
        </Modal>
      </div>
    );
  }

  const switchArrowHandler = (id: string) => {
    setCategoryState((oldState) => ({
      ...oldState,
      [id]: !oldState[id],
    }));
  };

  return (
    <Layout
      url="/admin/category-management/"
      title="Category Management"
      disableIndex
    >
      <div className="max-w-screen-xl	CategoryManagement-wrapper">
        <h3 className="card-title text-center">Categories</h3>
        <div className="flex justify-end">
          <button
            className="justify-center   flex items-center px-3 border-2 border-interaction-enabled shadow-sm text-xs font-bold	 font-emp rounded-md text-white bg-background-dark "
            onClick={() => createModal.open()}
          >
            <span className="text-2xl text-interaction-light pr-1">
              {' '}
              &#xFF0B;
            </span>{' '}
            <span className="pr-2"> Add Category</span>
          </button>
        </div>
        <div className="flex border-b border-gray-700">
          <div className="w-1/4 font-emp opacity-40	 text-white">Categories</div>
          <div className="w-1/4 font-emp opacity-40	 text-white ">
            Density (kg/m3)
          </div>
        </div>

        {orderedCategories.map(
          (category) =>
            (!category.parentId || categoryState[category.parentId]) && (
              <CategoryRow
                key={category.id}
                category={category}
                arrow={categoryState}
                switchArrow={() => switchArrowHandler(category.id)}
                deleteCategory={deleteModal.open}
                updateCategory={(category) => {
                  updateFormik.resetForm({
                    values: {
                      switchCategory: category.parentId
                        ? 'subcategory'
                        : 'category',
                      name: category.name,
                      density: category.density,
                    },
                  });
                  updateModal.open(category);
                }}
              />
            )
        )}

        {renderModals()}
      </div>
    </Layout>
  );
};

export default CategoryManagementPage;
