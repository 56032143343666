import { useModal } from '@lib';
import clsx from 'clsx';
import * as React from 'react';
import { FC, useCallback, useState } from 'react';

interface MenuProps {
  deleteButton: string;
  editButton: string;
  deleteHandler: () => void;
  editHandler: () => void;
  showOwnerEdit: boolean;
  markAsCompleted?: boolean;
  completeHandler?: () => void;
  isCompleted?: string;
}

const CategoryDotsMenu: FC<MenuProps> = ({
  deleteButton,
  editButton,
  deleteHandler,
  editHandler,
  showOwnerEdit,
  markAsCompleted,
  completeHandler,
  isCompleted,
}) => {
  const [dotsMenu, setDotsMenu] = useState<boolean>(false);
  const menuControl = useModal();

  const createButtonCallback = useCallback(
    (callback) => () => {
      setDotsMenu(false);
      return callback();
    },
    [setDotsMenu]
  );

  return (
    <>
      {menuControl.isOpen && (
        <div
          className="fixed inset-0"
          aria-hidden="true"
          onClick={menuControl.close}
        >
          <div
            className="absolute inset-0 bg-almostTransparent"
            onClick={() => setDotsMenu(false)}
          />
        </div>
      )}
      <div onClick={menuControl.open}>
        {showOwnerEdit ? (
          <div
            onClick={() => {
              setDotsMenu(!dotsMenu);
            }}
          >
            <svg
              className="h-4 text-trade-mine"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        ) : markAsCompleted ? (
          <div
            onClick={() => {
              setDotsMenu(!dotsMenu);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>
          </div>
        ) : (
          <div
            className={'Three-dots'}
            onClick={() => {
              setDotsMenu(!dotsMenu);
            }}
          />
        )}
        <div
          className={clsx(
            ' bg-interaction-statusDots absolute flex-col  text-interaction-transparent text-left rounded-sm items-center font-body text-sm',
            dotsMenu ? 'block' : 'hidden',
            markAsCompleted ? 'w-40 -ml-40' : 'w-24 -ml-24'
          )}
        >
          <div className="  pl-2 h-6 flex items-center">
            <button
              className="no-underline text-left text-interaction-transparent w-full "
              onClick={createButtonCallback(editHandler)}
            >
              {editButton}
            </button>
          </div>
          <hr className={'m-0 bg-interaction-border'} />
          <div className="pl-2 h-6 flex items-center">
            <button
              className="pb-0 w-full text-left"
              onClick={createButtonCallback(deleteHandler)}
            >
              {deleteButton}
            </button>
          </div>
          {markAsCompleted && (
            <>
              <hr className={'m-0 bg-interaction-border'} />
              <div className="pl-2 h-6 flex items-center">
                <button
                  className="pb-0 w-full text-left"
                  onClick={completeHandler}
                >
                  {isCompleted}
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CategoryDotsMenu;
